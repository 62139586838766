<template>
  <div class="rn-contact-area rn-section-gapsiber2 idcard-page">
    <div class="container elevation-24">
      <v-tabs v-model="activeTab">
        <v-tab class="tab-style">Sales</v-tab>
        <v-tab class="tab-style">Project</v-tab>
        <v-tab class="tab-style">Internal</v-tab>
        <v-tab class="tab-style">External</v-tab>
        <v-spacer />

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ma-auto mr-2" small elevation="1" color="primary" v-bind="attrs" v-on="on" @click="dialog = true">
              <v-icon>
                mdi-lock-reset
              </v-icon>
            </v-btn>
          </template>
          <span>Change Password</span>
        </v-tooltip>
        <v-dialog transition="dialog-bottom-transition" v-model="dialog" width="500">
          <v-card style="border-radius: 10px">
            <v-toolbar
              color="secondary"
              class="pa-0"
              dark
            >
              <div class="pa-2">
                <v-btn icon small @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <span style="font-size: 24px; font-weight: 600;">Change Password </span>
            </v-toolbar>
            <div class="pa-5">
              <v-text-field class="passwordstyle" background-color="#F0F0F0" v-model="oldPassword" @keyup.enter="submitGanti"
                label="Password Lama" :type="typetext" :append-icon="icontext" v-on:click="messagemsg1 = ''"
                @mousedown="(e) => handleappend(e)" @mouseup="(e) => handleappend(e)"
                :error-messages="messagemsg1.length > 0 ? messagemsg1 : ''" />

              <v-text-field class="passwordstyle" background-color="#F0F0F0" v-model="newPassword" @keyup.enter="submitGanti"
                label="Password Baru" :type="typetext" :append-icon="icontext" v-on:click="messagemsg2 = ''"
                @mousedown="(e) => handleappend(e)" @mouseup="(e) => handleappend(e)"
                :error-messages="messagemsg2.length > 0 ? messagemsg2 : ''" />

              <v-text-field class="passwordstyle" background-color="#F0F0F0" v-model="confirmPassword"
                @keyup.enter="submitGanti" label="Konfirmasi Password Baru" :type="typetext" :append-icon="icontext"
                v-on:click="messagemsg2 = ''" @mousedown="(e) => handleappend(e)" @mouseup="(e) => handleappend(e)"
                :error-messages="messagemsg2.length > 0 ? messagemsg2 : ''" />

              <v-btn elevation="1" color="primary" block v-on:click="submitGanti"
                :disabled="!oldPassword || !newPassword || !confirmPassword">Ganti Password</v-btn>
            </div>
          </v-card>
        </v-dialog>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="logout" class="ma-auto mr-4" small elevation="1" color="primary" v-bind="attrs" v-on="on">
              <v-icon dark>
                mdi-logout-variant
              </v-icon>
            </v-btn>
          </template>
          <span>Logout</span>
        </v-tooltip>
      </v-tabs>
      <v-tabs-items v-model="activeTab">
        <v-tab-item :key="1">
          <v-card class="pa-1">
            <PenomoranSales />
          </v-card>
        </v-tab-item>

        <v-tab-item :key="2">
          <v-card class="pa-1">
            <PenomoranProject />
          </v-card>
        </v-tab-item>

        <v-tab-item :key="3">
          <v-card class="pa-1">
            <SuratKeluarInternal />
          </v-card>
        </v-tab-item>

        <v-tab-item :key="4">
          <v-card class="pa-1">
            <SuratKeluarExtreme />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import PenomoranSales from '../components/menu-no-surat/PenomoranSales.vue';
import PenomoranProject from '../components/menu-no-surat/PenomoranProject.vue';
import SuratKeluarInternal from '../components/menu-no-surat/SuratKeluarInternal.vue';
import SuratKeluarExtreme from '../components/menu-no-surat/SuratKeluarExternal.vue'
import baseUrl from "../about.js"
import axios from 'axios'
import { postMethod } from '../plugins/restAPI';

const pbkdf2 = require("pbkdf2");

export default {
  components: {
    PenomoranProject,
    PenomoranSales,
    SuratKeluarInternal,
    SuratKeluarExtreme
  },
  data() {
    return {
      activeTab: 0,
      baseUrl: baseUrl,
      id: '',
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      dialog: false,
      messagemsg1: "",
      messagemsg2: "",
      icontext: "mdi-eye",
      typetext: "password"
      // 
    };
  },
  computed: {
    // 
  },
  methods: {
    logout() {
      this.$router.push("/login");
    },
    submitGanti() {
      try {
        let vm = this;
        let userID = vm.$route.params.name
        // let userPass = vm.oldPassword;
        let userPass = pbkdf2
          .pbkdf2Sync(vm.oldPassword, userID, 1, 32, "sha512")
          .toString("hex");
        fetch(vm.baseUrl + "/staff-profile/" + userID + ".json", { cache: 'no-store' }).then((res) => {
          if (res.status == 200) {
            res.json().then((result) => {
              console.log(userPass, result.password)
              if (userPass == result.password) {
                console.log("masukkk");
                // vm.messagemsg1 = "the password is correct..";
                // alert("the password is correct..");
                if (vm.newPassword == vm.confirmPassword) {
                  // vm.messagemsg2 = "the password is same..";
                  // alert("the password is same..");
                  let passToPush = pbkdf2
                    .pbkdf2Sync(vm.newPassword, userID, 1, 32, "sha512")
                    .toString("hex");
                  result.password = passToPush
                  postMethod(vm.baseUrl + "/staff-profile/", JSON.stringify(result), userID + ".json").then(resPost => {
                    resPost ? alert('success change') : alert('failed posting')
                    console.log('post indexing :', resPost.status, resPost)
                    // return true
                    this.dialog = false
                  })

                } else {
                  alert('the password is different..')
                  // vm.messagemsg2 = "the password is different..";
                }


              } else {
                alert('the password is incorrect..')
                // vm.messagemsg1 = "the password is incorrect..";
              }
            })
          }
        })
      } catch (error) {
        console.log(error)
        return false
      }

    },
    handleappend(e) {
      if (this.typetext == "password") {
        this.typetext = "text"
        this.icontext = "mdi-eye-off"
      } else if (this.typetext == "text") {
        this.typetext = "password"
        this.icontext = "mdi-eye"
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.container {
  display: block;
  overflow-y: auto !important;
  text-align: left;
  padding-bottom: 20px;
  height: auto;
  border-radius: 10px;
  padding: 15px 25px 30px 25px;
  background-color: #fff;
}

@media screen and (max-width: 767px) {
  .container {
    width: 90%;
    height: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1920px) {
  .container {
    width: 630px !important;
    max-height: 900px !important;
    // overflow-y: auto;
  }
}

// .guidance-style {
//   border-radius: 0px 10px 10px 0px !important;
//   background-color: #152939 !important;
//   width: 100%;
//   height: 100%;
// }

.idcard-page {
  // background-color: #E4E4E1;
  background: radial-gradient(at top center, rgba(255,255,255,0.03) 0%, rgba(0,0,0,0.03) 100%), linear-gradient(to top, rgba(255,255,255,0.1) 0%, rgba(143,152,157,0.60) 100%), url("../assets/images/bg/9150533.jpg") center/cover;
 	background-blend-mode: normal, multiply;
  overflow-y: auto !important;
  height: 100vh;
  width: 100vw;
}
.passwordstyle {
  font: small-caption;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
.tab-style {
  font-family: 'Noto Sans', sans-serif !important;
  font-weight: 700;
  font-size: 16px;
}
.v-text-field input {
  font-family: 'Noto Sans', sans-serif !important;
  font-weight: 400;
}
.v-text-field label {
  font-family: 'Noto Sans', sans-serif !important;
  font-weight: 500;
  letter-spacing: 1px !important;
}
</style>